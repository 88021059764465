import jQuery from 'jquery'
import $ from 'jquery'
import jPlayer from 'jplayer'

$( document ).on('turbolinks:load', function() {
  var project_id = $("#ppt_project_project_id").val();
  var recorder, gumStream;
  
  $("#mp3_player").jPlayer({
    ready: function () {
      // Don't load anything yet
    },
    supplied: "mp3"
  });
  
  $(".mp3_control").on('click', function() {
    var slide_id = $(this).data('id');
    if ($("#mp3_player").data().jPlayer.status.paused) {
      $("#mp3_player").jPlayer( "setMedia", { mp3: "/ppt_projects/" + project_id + "/download/" + slide_id + "?ts=" + $.now() } );
      $("#mp3_player").jPlayer( "option", "cssSelectorAncestor", "#mp3_control_" + slide_id );
      $("#mp3_player").jPlayer( "play" );
    }
    return false;
  });
  
  var $status = $('#save-status'),
      $textbox = $('.slideshow-transcript-box, .slideshow-name-box, .existing-quiz-question .quiz-question, .existing-quiz-question .quiz-answer, .existing-quiz-question .quiz-response, .existing-quiz-question .quiz-correct-answer');
  
  $textbox.change(function () {
    var slide_id = $(this).data('slide-id').toString();
    var textvalue = $(this).val();
    var fieldname = $(this).data('field-name').toString();
    
    $status.attr('class', 'pending');
    // Make ajax call to save data.
    console.log("Saving changes");
    console.log('url: ' + '/ppt_projects/' + project_id + '/save_slide/' + slide_id);
    $.ajax({
      type: 'POST',
      url: '/ppt_projects/' + project_id + '/save_slide/' + slide_id,
      data: {
        authenticity_token: $('meta[name=csrf-token]').attr("content"),
        field: fieldname,
        value: textvalue
      },
      success: function(data){
        $status.attr('class', 'saved');
      },
      failure: function(data){
        $status.attr('class', 'error');
      }
    });
  });
  
  $(".slideshow-category-box").on('change', function() {
    var slide_id = $(this).data('slide-id').toString();
    var category_id = $(this).val();
    
    $.ajax({
      type: 'POST',
      url: '/ppt_projects/' + project_id + '/move_to_category/' + slide_id,
      data: {
        authenticity_token: $('meta[name=csrf-token]').attr("content"),
        category_id: category_id
      },
      success: function(data){
        location.reload();
      },
      failure: function(data){
        $status.attr('class', 'error');
      }
    });
  });
  
  $(".new_category_link").on('click', function() {
    var category_name = prompt("Please enter a name for your new category");
    if (category_name != null && category_name != "") {
      $.ajax({
        type: 'POST',
        url: '/ppt_projects/' + project_id + '/create_category',
        data: {
          authenticity_token: $('meta[name=csrf-token]').attr("content"),
          category_name: category_name
        },
        success: function(data){
          var category_id = data["category_id"];
          location.href = "/ppt_projects/" + project_id + "/category/" + category_id;
        },
        failure: function(data){
          $status.attr('class', 'error');
        }
      });
    }
    
  });
  
  $('[data-toggle="tooltip"]').tooltip();
  
  $("#btn-edit-view").on('click', function() {
    location.href = "?view=edit";
  });
  
  $("#btn-arrange-view").on('click', function() {
    location.href = "?view=arrange";
  });
  
  $(".slide-arrange-view").sortable({
    cursor: "move",
    items: "div.ppt-slide-row:not(.sort-disabled)",
    update: function( event, ui ) {
      var slide_boxes = $(this);
      var slide_id = ui.item.data('slide-id');
      var position = ui.item.index() + 1;
      var item_count = slide_boxes.data('item-count');
      
      // Disable sorting
      slide_boxes.sortable( "disable" );
      
      $.post('/ppt_projects/' + project_id + '/save_position/' + slide_id, {
        position: position,
        item_count: item_count,
        authenticity_token: $('meta[name=csrf-token]').attr("content")
      }).always(function(data) {
        slide_boxes.sortable( "enable" );
      });
    }
  });
  
  $(".slide-categories").sortable({
    cursor: "move",
    items: "li:not(.sort-disabled)",
    update: function( event, ui ) {
      var category_boxes = $(this);
      var category_id = ui.item.data('category-id');
      var position = ui.item.index();
      var item_count = category_boxes.data('item-count');
      
      // Disable sorting
      category_boxes.sortable( "disable" );
      
      $.post('/ppt_projects/' + project_id + '/save_category_position/' + category_id, {
        position: position,
        item_count: item_count,
        authenticity_token: $('meta[name=csrf-token]').attr("content")
      }).always(function(data) {
        category_boxes.sortable( "enable" );
      });
    }
  });
  
  $(".edit-category-icon").on('click', function() {
    var old_category_name = $(this).data("category-name");
    var category_id = $(this).data("category-id");
    var category_name = prompt("Please enter a new category name", old_category_name);
    if (category_name != null && category_name != "" && category_name != old_category_name) {
      $.ajax({
        type: 'POST',
        url: '/ppt_projects/' + project_id + '/rename_category/' + category_id,
        data: {
          authenticity_token: $('meta[name=csrf-token]').attr("content"),
          category_name: category_name
        },
        success: function(data){
          location.href = "/ppt_projects/" + project_id + "/category/" + category_id;
        },
        failure: function(data){
          $status.attr('class', 'error');
        }
      });
    }
  });
  
  $("#add-quiz-question-link").on('click', function() {
    $(this).hide();
    $("#add-quiz-question-form").show();
  });
  
  $("#save-quiz-button").on("click", function() {
    var validation_errors = [];
    var quiz_question = get_quiz_field("quiz_question");
    var quiz_answer_a = get_quiz_field("quiz_answer_a");
    var slide_category_id = get_quiz_field("slide_category_id");
    var correct_answer = get_quiz_field("correct_answer");
    
    if (quiz_question == "") {
      validation_errors.push("Please enter a quiz question.");
    }
    if (quiz_answer_a == "") {
      validation_errors.push("Please enter at least one multiple choice answer.");
    }
    if (correct_answer === undefined) {
      validation_errors.push("You must select the correct answer for your question.");
    }
    if (validation_errors.length > 0) {
      alert("Unable to save quiz question. " + validation_errors.join(' '));
    } else {
      
      // Save the new question
      $.ajax({
        type: 'PATCH',
        url: '/ppt_projects/' + project_id + '/save_quiz_question',
        data: {
          authenticity_token: $('meta[name=csrf-token]').attr("content"),
          question_slide: {
            slide_category_id: slide_category_id,
            quiz_question: quiz_question,
            quiz_answer_a: quiz_answer_a,
            quiz_response_a: get_quiz_field("quiz_response_a"),
            quiz_answer_b: get_quiz_field("quiz_answer_b"),
            quiz_response_b: get_quiz_field("quiz_response_b"),
            quiz_answer_c: get_quiz_field("quiz_answer_c"),
            quiz_response_c: get_quiz_field("quiz_response_c"),
            quiz_answer_d: get_quiz_field("quiz_answer_d"),
            quiz_response_d: get_quiz_field("quiz_response_d"),
            correct_answer: correct_answer
          }
        },
        success: function(data){
          if (slide_category_id) {
            location.href = "/ppt_projects/" + project_id + "/category/" + slide_category_id;
          } else {
            location.href = "/ppt_projects/" + project_id;
          }
        },
        failure: function(data){
          $status.attr('class', 'error');
        }
      });      
    }
  });
  
  function get_quiz_field(field) {
    var prefix = "ppt_project_question_slide_";
    
    if (field == "correct_answer") {
      return $("input[name='ppt_project[question_slide][correct_answer]']:checked").val();
    } else {
      return $("#" + prefix + field).val();
    }
  }
  
  $("#main-project-dummy-form").on("submit", function(e) {
    e.preventDefault();
  });
  
  $(".record-button").on("click", function() {
    var the_link = $(this);
    var slide_id = $(this).data('id');
    
    if (recorder && recorder.state == "recording") {
      console.log("Recording stopped for slide " + slide_id);
      recorder.stop();
      gumStream.getAudioTracks()[0].stop();
      $(this).removeClass("recording-active");
    } else {
      navigator.mediaDevices.getUserMedia({
        audio: true
      }).then(function(stream) {
        gumStream = stream;
        recorder = new MediaRecorder(stream);
        recorder.ondataavailable = function(e) {
          var recording_url = URL.createObjectURL(e.data);
          var recording_blob = e.data;
          console.log("Recording available at " + recording_url);
          var upload = new Upload(slide_id, recording_blob);
          upload.doUpload();
        };
        console.log("Recording started for slide " + slide_id);
        the_link.addClass("recording-active");
        recorder.start();
      });
    }
    
  });
  
  var Upload = function (slide_id, file) {
    this.file = file;
    this.slide_id = slide_id;
    this.auth_token = $('meta[name=csrf-token]').attr("content");
  };

  Upload.prototype.getType = function() {
    return this.file.type;
  };
  Upload.prototype.getSize = function() {
    return this.file.size;
  };
  Upload.prototype.doUpload = function () {
    var that = this;
    var formData = new FormData();

    // add assoc key values, this will be posts values
    formData.append("file", this.file);
    formData.append("authenticity_token", this.auth_token);

    $.ajax({
      type: "POST",
      url: '/ppt_projects/' + project_id + '/upload_audio/' + that.slide_id,
      success: function (data) {
        // your callback here
        console.log("Audio file successfully uploaded for slide " + that.slide_id);
      },
      error: function (error) {
        // handle error
        console.log("Error uploaded audio file for slide " + that.slide_id);
      },
      async: true,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      timeout: 60000
    });
  };
  
});